@keyframes coinRain {
  0% {
    transform: translateY(-5dvh);
    width: "50px";
    opacity: 1;
  }
  100% {
    transform: translateY(100dvh);
    width: "50px";

    opacity: 0;
  }
}

.coin {
  position: fixed;
  animation: coinRain 4s linear;
  opacity: 0;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loadingship {
  animation: fadeInOut 5s infinite; /* Change 5s to your desired speed */
}
